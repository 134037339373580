<template>
  <div class="tw-single-row-text-element !m-0 md:!mx-8 md:!mb-8 md:!mt-0 md:max-w-[280px]">
    <div :class="{ 'pointer-events-none': isEditorMode }">
      <WebInput
        v-model="textValue"
        name="singlerowtext"
        :char-limit="charLimit"
        hide-char-limit-text
        type="text"
        :label="labelText"
        :label-style="labelStyle"
        :page-options="pageOptions"
        :rules="{ required: !!config?.required }"
        :validate-on-update="true"
      />
    </div>
    <div v-if="$slots.default" class="tw-single-row-text-element__bottom">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, computed, toRefs, onBeforeMount } from 'vue';
import WebInput from '@shared/components/input/index.vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { globalEmit } from '@shared/utils/helpers';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['singlerowtext']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  element: { type: Object as PropType<ElementType<'singlerowtext'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const textValue = ref('');
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));
const { element, pageOptions } = toRefs(props);
const { labelStyle, labelText } = useLabelOptions({ element, pageOptions });
const charLimit = computed(() => (props.config.input?.max?.active ? props.config.input.max.limit : undefined));

const canSubmit = computed(() => {
  const textLength = textValue.value?.length || 0;

  if (props.config.required) {
    if (props.config.input?.min?.active && textLength < props.config.input.min.limit) {
      return false;
    }
    return !!textLength;
  }

  return true;
});

function finish(event?: any) {
  if (!canSubmit.value) return;
  globalEmit('nextQuestion', [event, textValue.value]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    textValue.value = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'singlerowtext',
    });
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
})
</script>

<style lang="postcss" scoped>
.tw-single-row-text-element {
  :deep(label) {
    word-break: break-word;
  }
}
</style>
